<template>
  <svg
    class="arrow-nav-left"
    width="100%"
    height="100%"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 1L0.999999 7L0.999999 7L5.66667 13M0.999999 7L13 7"
      stroke="currentColor"
    />
  </svg>
</template>
